import React from 'react';

// import { Container } from './styles';



const VideosAproveAllScreen: React.FC = () => {
  return (

    <div>

      <h2>VideosAproveAllScreen</h2>

    </div>

  );
};



export default VideosAproveAllScreen;
