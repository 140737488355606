// // Backgrounds
// import _backgroundIndex2 from '../../assets/backgrounds/background_index2.png';
// import _backgroundIndex3 from '../../assets/backgrounds/background_index3.png';
// import _backgroundOrganizer from '../../assets/backgrounds/background_organizer.png';
// import _linkExtensions from '../../assets/backgrounds/link-extensions.png';


// // Drawables
// import _iconLove from '../../assets/drawables/icon_love.png';



// // Erros
// import _error403 from '../../assets/images/error_403.png';
// import _error404 from '../../assets/images/error_404.png';
// import _error500 from '../../assets/images/error_500.png';



// import _kitIcon from '../../assets/images/kit_icon.png';
// import _kitLogo from '../../assets/images/kit_logo.png';
// import _kitPhotos from '../../assets/images/kit_photos.png';
// import _kitUI from '../../assets/images/kit_ui.png';



// // Markets
// import _marketAppStore from '../../assets/images/market_app_store.png';
// import _marketGooglePlay from '../../assets/images/market_google_play.png';
// import _marketWindowsStore from '../../assets/images/market_windows_store.png';


// // Separators
// import _separatorHorizontal from '../../assets/images/separator_horizontal.png';
// import _separatorVertical from '../../assets/images/separator_vertical.png';


// Placeholders
import _placeholderAvatarUser from '../../assets/backgrounds/banner_avatar_user.png';


// Items
import _iconSymbolMan from '../../assets/images/icon_symbol_man.png';
import _iconSymbolTransman from '../../assets/images/icon_symbol_transman.png';
import _iconSymbolTranswoman from '../../assets/images/icon_symbol_transwoman.png';
import _iconSymbolToman from '../../assets/images/icon_symbol_woman.png';

import _logoBi from '../../assets/images/logo_bi.png';
import _logoGay from '../../assets/images/logo_gay.png';
import _logoHetero from '../../assets/images/logo_hetero.png';
import _logoLesbian from '../../assets/images/logo_lesbian.png';
import _logoTransman from '../../assets/images/logo_transman.png';
import _logoTranswoman from '../../assets/images/logo_transwoman.png';


// // Logos
import _logoAppIcon from '../../assets/logos/logo_app_icon.png';
import _logoAppName from '../../assets/logos/logo_app_name.png';
import _rankingHeaderYearly from '../../assets/backgrounds/ranking-header-yearly.png';
import _rankingStarsSeparator from '../../assets/backgrounds/ranking-stars-separator.png';
import _rankingContents from '../../assets/backgrounds/ranking-contents.png';
import _rankingYearlyIcon from '../../assets/images/ranking-yearly-icon.png';
import _star from '../../assets/images/star.png';
import _trophy from '../../assets/images/trophy.png';
import _rankingFirwork from '../../assets/images/ranking-firwork.png';

import _rankingStripesLeft from '../../assets/backgrounds/ranking-stripes-left.png';
import _rankingStripesRight from '../../assets/backgrounds/ranking-stripes-right.png';
import _rankingStarsLeft from '../../assets/backgrounds/ranking-stars-left.png';
import _rankingStarsRight from '../../assets/backgrounds/ranking-stars-right.png';



export const BACKGROUNDS = {
  // backgroundIndex2:                 _backgroundIndex2,
  // backgroundIndex3:                 _backgroundIndex3,
  // backgroundPRO:                    _backgroundOrganizer,
  rankingStarsSeparator:            _rankingStarsSeparator,
  rankingContents:                  _rankingContents,

  rankingStarsLeft:                 _rankingStarsLeft,
  rankingStarsRight:                _rankingStarsRight,
  rankingStripesLeft:               _rankingStripesLeft,
  rankingStripesRight:              _rankingStripesRight,
};


export const BANNERS = {
  rankingHeaderYearly:              _rankingHeaderYearly,
};


export const DRAWABLES = {
  rankingFirwork:                   _rankingFirwork,
  rankingYearlyIcon:                _rankingYearlyIcon,
  star:                             _star,
  trophy:                           _trophy,
};


// export const ERRORS = {
//   error403:                         _error403,
//   error404:                         _error404,
//   error500:                         _error500,
// };


// export const IMAGES = {
//   linkExtensions:                   _linkExtensions,
// };


export const ITEMS = {
  iconSymbolMan:                    _iconSymbolMan,
  iconSymbolTransman:               _iconSymbolTransman,
  iconSymbolTranswoman:             _iconSymbolTranswoman,
  iconSymbolToman:                  _iconSymbolToman,

  logoBi:                           _logoBi,
  logoGay:                          _logoGay,
  logoHetero:                       _logoHetero,
  logoLesbian:                      _logoLesbian,
  logoTransman:                     _logoTransman,
  logoTranswoman:                   _logoTranswoman,
};



export const ITEMS_EMPTY = {
  // checkinEmpty:                     _checkinEmpty,
  noBankAccount:                    _placeholderAvatarUser, // _noBankAccount,
  // noEventsPast:                     _noEventsPast,
  // noEventsSketch:                   _noEventsSketch,
  // noEventsUpcoming:                 _noEventsUpcoming,
  // noOrderCategories:                _noOrderCategories,
  // noOrderItems:                     _noOrderItems,
  // noRules:                          _noRules,
  // noBar:                            _noBar,
};


export const LOGOS = {
  logoAppIcon:                      _logoAppIcon,
  logoAppName:                      _logoAppName,
  
  //   logoPROIconColor:                 _logoPROIconColor,
  
  //   logoTvIconWhite:                  _logoTvIconWhite,
  
  //   logoPersasNameWhite:              _logoPersasNameWhite,
  //   logoPersasIconWhite:              _logoPersasIconWhite,
};


// export const MARKETS = {
//   marketGooglePlay:                 _marketGooglePlay,
//   marketAppStore:                   _marketAppStore,
//   marketWindowsStore:               _marketWindowsStore,
// };



export const PLACEHOLDERS = {
  placeholderAvatarCompany:         _placeholderAvatarUser, // _placeholderAvatarCompany,
  placeholderAvatarUser:            _placeholderAvatarUser,
  // placeholderEvent:                 _placeholderEvent,
};


// export const SEPARATORS = {
//   separatorHorizontal:              _separatorHorizontal,
//   separatorVertical:                _separatorVertical,
// };


// export const OTHERS = {
//   kitIcon: _kitIcon,
//   kitLogo: _kitLogo,
//   kitPhotos: _kitPhotos,
//   kitUI: _kitUI,
// };



const Images = {
  ...BACKGROUNDS,
  ...BANNERS,
  ...DRAWABLES,
  // ...ERRORS,
  // ...IMAGES,
  ...ITEMS,
  ...ITEMS_EMPTY,
  ...LOGOS,
  // ...MARKETS,
  ...PLACEHOLDERS,
  // ...SEPARATORS,

  // ...OTHERS,
};



export default Images;
