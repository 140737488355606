import React from 'react';
import { useHistory } from 'react-router-dom';
import NameRoutes from '../../../navigation/names';

// import { Container } from './styles';

const PlansAllScreen: React.FC = () => {
  const history = useHistory();
  return (

    <div>

      <h1>
        Planos
      </h1>

      <button
        onClick={() => {
          history.push(NameRoutes.PlansFormScreen);
        }}>
        Criar plano
      </button>



      <div>

        <h2>
          Rent
        </h2>

        <h3>
          Masculino
        </h3>

        <h3>
          Feminino
        </h3>

        <h3>
          Trans Masculino
        </h3>

        <h3>
          Trans Feminino
        </h3>

      </div>

    </div>

  );
};



export default PlansAllScreen;
