import React from 'react';
import { Screen } from '../../components/Composh';
import TabYearlyCrush from './YearlyCrush';

// import { Container } from './styles';



const RankingsScreen: React.FC = () => {
  return (

    <Screen>

      <TabYearlyCrush />

    </Screen>

  );
};



export default RankingsScreen;
