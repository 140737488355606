import React from 'react';

// import { Container } from './styles';



const SubCategoriesShowAllScreen: React.FC = () => {
  return (

    <div>

      <h2>Ver SubCategorias</h2>

    </div>

  );
};



export default SubCategoriesShowAllScreen;
