import ApiWS from '../index';



export const setAuthHeaders = (token: string) => {
  (ApiWS.defaults.headers as any).authorization = token;
};



export const AuthenticateUser = async (data: any) => {
  try {
    const url = '/auth';
    const response = await ApiWS.post(url, data);

    if (response.data.token) {
      console.log('TOKEN', response.data.token);

      const newToken = response.data.token;

      const userData = response.data.user;
      userData.picture = userData.picture?.link;   // copying link to uri

      return {
        token: newToken,
        user: userData,
      };
    }
    else {
      console.log('TOKEN NOT PRESENT');
      console.log(response.data);
    }
  }
  catch (error: any) {
    console.error('Authentication Error', JSON.stringify(error.response, null, 2));
    return null;
  }
};



export const TryRevalidateSession = async (token: string | null) => {
  try {
    if (token) {
      const url = '/auth/revalidate';
      const response = await ApiWS.post(url, { token });
      return response.data;
    }
    else {
      return null;
    }
  }
  catch (error: any) {
    console.log('ERROR: ', error);
    return null;
  }
};



export const loadFullUserCompany = async (userId: any) => {
  const userInfoResponse = await ApiWS.get(`/users/${userId}`, {
    timeout: 90000,
  });

  const user_companies = userInfoResponse.data.user_companies;
  for (let i = 0; i < user_companies.length; i++) {
    const company = user_companies[i];

    const companyImages = await ApiWS.get(`/pictures/company/${company.company.id}`);
    company.company.logo = '';
    company.company.images = companyImages.data;

    for (let j = 0; j < companyImages.data.length; j++) {
      if (companyImages.data[j].name === 'Company Logo') {
        company.company.logo = companyImages.data[j].link;
      }
    }
  }

  return user_companies;
};
