// Ranking
import _rankingYearlyVideo from '../../assets/videos/ranking-yearly-video.mp4';



export const RANKINGS = {
  rankingYearlyVideo:               _rankingYearlyVideo,
};



const Videos = {
  ...RANKINGS,
};



export default Videos;
