import React from 'react';

import {
  Images,
  Videos,
} from '../../../common/constants';

import {
  Content,
} from '../../../components/Composh';

import {
  HolidayCountdownContainer,
  HolidayCountdownTitle,
  HolidayCountdownInner,
  HolidayCountdownNumberInner,
  HolidayCountdownNumberText,
  HolidayCountdownDateText,
  PromoHeaderHolidayPanel,
  PromoHeaderHolidayImage,
  PromoHeaderSeparatorPanel,
  PromoYearSelectContent,
  PromoYearSelectTitle,
  PromoDetailsPanel,
  PromoDetailsContentPanel,
  PromoDetailsWantedContent,
  PromoWantedTitle,
  PromoWantedText,
  PromoWantedImage,
  PromoWantedVideoContent,
  PromoWantedItemVideo,
  PromoRankingContent,
  PromoRankingHeaderContent,
  PromoRankingHeaderView,
  PromoRankingTrophyView,
  PromoRankingTrophyImage,
  PromoRankingTrophyText,
  PromoRankingInfoContent,
  PromoRankingInfoTitle,
  PromoRankingInfoText,
  PromoRankingInfoSpan,
  PromoRankingTableContent,
  PromoRankingTableComponent,
  RankingsTdRank,
  RankingsTdModelImage,
  RankingsModelButtonImage,
  RankingsModelImage,
  RankingsModelOnlineText,
  RankingsTdName,
  RankingsModelName,
  RankingsTdCollectibles,
  RankingsTdPrize,
} from './styled';



const TabYearlyCrush: React.FC = () => {
  return (

    <Content>

      <HolidayCountdownContainer>

        <HolidayCountdownTitle>
          BIG BANG 2023 Ends In:
        </HolidayCountdownTitle>


        <HolidayCountdownInner>

          <HolidayCountdownNumberInner>
            <HolidayCountdownNumberText>
              00
            </HolidayCountdownNumberText>

            <HolidayCountdownDateText>
              Days
            </HolidayCountdownDateText>
          </HolidayCountdownNumberInner>


          <HolidayCountdownNumberInner>
            <HolidayCountdownNumberText>
              00
            </HolidayCountdownNumberText>

            <HolidayCountdownDateText>
              Hours
            </HolidayCountdownDateText>
          </HolidayCountdownNumberInner>


          <HolidayCountdownNumberInner>
            <HolidayCountdownNumberText>
              00
            </HolidayCountdownNumberText>

            <HolidayCountdownDateText>
              Minutes
            </HolidayCountdownDateText>
          </HolidayCountdownNumberInner>


          <HolidayCountdownNumberInner>
            <HolidayCountdownNumberText>
              00
            </HolidayCountdownNumberText>

            <HolidayCountdownDateText>
              Seconds
            </HolidayCountdownDateText>
          </HolidayCountdownNumberInner>


        </HolidayCountdownInner>

      </HolidayCountdownContainer>



      <PromoHeaderHolidayPanel>
        <PromoHeaderHolidayImage
          alt={'Contest Header Displaying Models'}
          src={Images.rankingHeaderYearly}
        />
      </PromoHeaderHolidayPanel>



      <PromoHeaderSeparatorPanel
        src={Images.rankingStarsSeparator}
      />



      <PromoYearSelectContent>

        <PromoYearSelectTitle>
          2022
        </PromoYearSelectTitle>

        <PromoYearSelectTitle>
          2023
        </PromoYearSelectTitle>

      </PromoYearSelectContent>



      <PromoDetailsPanel>

        <PromoDetailsContentPanel>

          <PromoDetailsWantedContent>
            <PromoWantedTitle>
              BANGERS WANTED
            </PromoWantedTitle>

            <PromoWantedText>
              This 4th of July weekend, our explosive party guys want to ignite your summer! Use blazing Banger tips to light their and set your sizzling fantasies ablaze!<br />
            </PromoWantedText>

            <PromoWantedText>
              The sparks fly at midnight (12 AM EST) on July 1st. Brand new Bangers have been added to your tip bar. Send them to your favorite model and start your summer with a bang!
            </PromoWantedText>

            <PromoWantedImage
              alt={'big-bang Promo'}
              src={Images.rankingYearlyIcon}
            />
          </PromoDetailsWantedContent>


          <PromoWantedVideoContent>
            <PromoWantedItemVideo
              id={'introVideo'}
              controls>
              <source
                type={'video/mp4'}
                src={Videos.rankingYearlyVideo}
              />
            </PromoWantedItemVideo>
          </PromoWantedVideoContent>

        </PromoDetailsContentPanel>



        <PromoRankingContent>

          <PromoRankingHeaderContent>
            <PromoRankingHeaderView>

              <PromoRankingTrophyView>
                <PromoRankingTrophyImage
                  alt={'trophy image'}
                  src={Images.trophy}
                />
              </PromoRankingTrophyView>

              <PromoRankingTrophyText>
                Main Contests
              </PromoRankingTrophyText>

            </PromoRankingHeaderView>
          </PromoRankingHeaderContent>



          <PromoRankingInfoContent>
            <PromoRankingInfoTitle>
              Most Collected
            </PromoRankingInfoTitle>

            <PromoRankingInfoText>
              <PromoRankingInfoSpan>How It Works:</PromoRankingInfoSpan>
              {' '}
              For the Big Bang 2023, we will award the 10 performers who collect the most total Bangers with a cash prize depending on where they place.
            </PromoRankingInfoText>
          </PromoRankingInfoContent>


          <PromoRankingTableContent>
            <PromoRankingTableComponent>

              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Model</th>
                  <th>Name</th>
                  <th>Bangers Collected</th>
                  <th>Prize</th>
                </tr>
              </thead>


              <tbody>
                <tr>
                  <RankingsTdRank>
                    #1
                  </RankingsTdRank>
                  <RankingsTdModelImage>
                    <RankingsModelButtonImage href="/models/bios/paul-morisette/about.php" target="_blank" rel="noopener noreferrer" >
                      <RankingsModelImage
                        src='https://cdn5.vscdns.com/images/models/samples/4151534.jpg'
                      />
                    </RankingsModelButtonImage>

                    <RankingsModelOnlineText>Im online</RankingsModelOnlineText>
                  </RankingsTdModelImage>
                  <RankingsTdName>
                    <RankingsModelName href="/models/bios/paul-morisette/about.php" target="_blank" rel="noopener noreferrer">Paul Morisette</RankingsModelName>
                  </RankingsTdName>
                  <RankingsTdCollectibles>12,042</RankingsTdCollectibles>
                  <RankingsTdPrize>$1,500</RankingsTdPrize>
                </tr>


                <tr>
                  <RankingsTdRank>
                    #2
                  </RankingsTdRank>
                  <RankingsTdModelImage>
                    <RankingsModelButtonImage href="/models/bios/andres-dreamer/about.php" target="_blank" rel="noopener noreferrer" >
                      <RankingsModelImage
                        src='https://cdn5.vscdns.com/images/models/samples/4151534.jpg'
                      />
                    </RankingsModelButtonImage>
                  </RankingsTdModelImage>
                  <RankingsTdName>
                    <RankingsModelName href="/models/bios/andres-dreamer/about.php" target="_blank" rel="noopener noreferrer">Andres Dreamer</RankingsModelName>
                  </RankingsTdName>
                  <RankingsTdCollectibles>3,003</RankingsTdCollectibles>
                  <RankingsTdPrize>$1,000</RankingsTdPrize>
                </tr>
              </tbody>

            </PromoRankingTableComponent>
          </PromoRankingTableContent>



          <PromoRankingHeaderContent>
            <PromoRankingHeaderView>

              <PromoRankingTrophyView>
                <PromoRankingTrophyImage
                  alt={'star image'}
                  src={Images.star}
                />
              </PromoRankingTrophyView>

              <PromoRankingTrophyText>SPECIAL CONTESTS</PromoRankingTrophyText>

            </PromoRankingHeaderView>
          </PromoRankingHeaderContent>



          <PromoRankingInfoContent>
            <PromoRankingInfoTitle>Most Collected</PromoRankingInfoTitle>
            <PromoRankingInfoText>
              <PromoRankingInfoSpan>How It Works:</PromoRankingInfoSpan>
              {' '}
              For the Big Bang 2023, we will award the 10 performers who collect the most total Bangers with a cash prize depending on where they place.
            </PromoRankingInfoText>
          </PromoRankingInfoContent>

        </PromoRankingContent>

      </PromoDetailsPanel>

    </Content>

  );
};



export default TabYearlyCrush;
