import React from 'react';

// import { Container } from './styles';



export interface IProps {
  onPress?: any;
  onAntiFakePress?: any;
  onPicturePress?: any;
  onVideoPress?: any;
}



const CardModelApprove: React.FC<IProps> = (props: IProps) => {
  return (

    <div>

      <div>
        <div
          onClick={props.onPress}>

          <img
          />



          <div>
            <h3>
              Name
            </h3>

            <h3>
              username
            </h3>
          </div>

        </div>



        {true && (
          <div>
            <a>
              Ativar perfil
            </a>

            <a>
              Desativar perfil
            </a>
          </div>
        )}



        {false && (
          <div>
            <p>
              perfil desativado pelo usuario
            </p>
          </div>
        )}

      </div>



      <div>

        <a>
          <div>
            {/* ICON */} [Badge ativo ou não]
          </div>

          <p>
            Aprovar detalhes
          </p>
        </a>

        <a
          onClick={props.onAntiFakePress}>
          <div>
            {/* ICON */} [Badge ativo ou não]
          </div>

          <p>
            Midia anti-fake
          </p>
        </a>


        <a
          onClick={props.onPicturePress}>
          <div>
            {/* ICON */} [Badge ativo ou não]
          </div>

          <p>
            Imagens [+1 a aprovar]
          </p>
        </a>


        <a
          onClick={props.onVideoPress}>
          <div>
            {/* ICON */} [Badge ativo ou não]
          </div>

          <p>
            Videos [+1 a aprovar]
          </p>
        </a>

      </div>

    </div>

  );
};



export default CardModelApprove;
