import React from 'react';

// import { Container } from './styles';

const HeaderContact: React.FC = () => {
  return (

    <div id="header__contatos" className="ng-tns-c145-4">
      <div className="container ng-tns-c145-4">
        <div className="row align-items-center ng-tns-c145-4">
          <div id="header__contatos--telefones" className="col ng-tns-c145-4">
            <ul className="ng-tns-c145-4">
              <li className="telefone--header ng-tns-c145-4">
                <a rel="noreferrer" title="Telefone para contato" href="#" target="_blank" className="ng-tns-c145-4">
                  <strong className="ng-tns-c145-4">#</strong>
                </a>
              </li>
              <li className="whatsapp--header ng-tns-c145-4">
                <a

                  rel="noreferrer"
                  title="telefone para contato via celular ou whatsapp"
                  target="_blank"
                  className="ng-tns-c145-4"
                  href="#"
                >
                  {/* <fa-icon class="whatsapp-icon ng-tns-c145-4" _nghost-appexclusivasex-c64=""><i _ngcontent-appexclusivasex-c64="" className="fa-whatsapp fab"></i></fa-icon> */}
                  <strong className="whatsapp-number ng-tns-c145-4">#</strong>
                </a>
              </li>
            </ul>
          </div>
          <div id="header__contatos--opcoes" className="col text-right ng-tns-c145-4">
            <ul className="ng-tns-c145-4">
              <li className="pedidos--header ng-tns-c145-4">
                <a title="Conheça nossos pedidos" className="ng-tns-c145-4" href="/cliente/pedidos">
                  {/* <fa-icon class="chevron-down-icon ng-tns-c145-4" _nghost-appexclusivasex-c64=""><i _ngcontent-appexclusivasex-c64="" className="fa-chevron-down fas"></i></fa-icon> MEUS PEDIDOS */}
                </a>
              </li>
              <li className="lojas--header ng-tns-c145-4">
                <button title="Conheça nossas lojas" className="ng-tns-c145-4">
                  {/* <fa-icon class="store-icon ng-tns-c145-4" _nghost-appexclusivasex-c64=""><i _ngcontent-appexclusivasex-c64="" className="fa-store fas"></i></fa-icon> NOSSAS LOJAS */}
                </button>
              </li>
              <li className="fale-conosco--header ng-tns-c145-4">
                <a title="Entre em contato conosco!" className="ng-tns-c145-4" href="/contato">
                  {/* <fa-icon class="tel-icon ng-tns-c145-4" _nghost-appexclusivasex-c64=""><i _ngcontent-appexclusivasex-c64="" className="fa-phone-alt fas"></i></fa-icon> FALE CONOSCO */}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>


  );
};



export default HeaderContact;
