import IAppRoute from '../../interfaces/app-route.interface';

import AccessScreen from '../../screens/Access';
import HomeScreen from '../../screens/Home';
import RankingsScreen from '../../screens/Rankings';

import ModelesDetailsScreen from '../../screens/Secretio/Modeles/Details';
import ModelesAproveAllScreen from '../../screens/Secretio/Modeles/ShowAll';
import AntiFakeAllScreen from '../../screens/Secretio/Photos/AntiFake/ShowAll';
import PicturesAproveAllScreen from '../../screens/Secretio/Photos/Pictures/ShowAll';
import PlansAllScreen from '../../screens/Secretio/Plans';
import PlansFormScreen from '../../screens/Secretio/Plans/Forms';
import VideosAproveAllScreen from '../../screens/Secretio/Videos/ShowAll';

import CategoriesCreateScreen from '../../screens/Stream/Categories/Create';
import CategoriesShowAllScreen from '../../screens/Stream/Categories/ShowAll';
import SubCategoriesCreateScreen from '../../screens/Stream/CategoriesSub/Create';
import SubCategoriesShowAllScreen from '../../screens/Stream/CategoriesSub/ShowAll';
import ChannelsCreateScreen from '../../screens/Stream/Channels/Create';
import ChannelsShowAllScreen from '../../screens/Stream/Channels/ShowAll';
import StarsCreateScreen from '../../screens/Stream/Stars/Create';
import StarsShowAllScreen from '../../screens/Stream/Stars/ShowAll';
import VideosCreateScreen from '../../screens/Stream/Videos/Create';
import VideosShowAllScreen from '../../screens/Stream/Videos/ShowAll';

import NameRoutes from '../names';



const ScreenRoutes: IAppRoute[] = [

  {
    path: NameRoutes.HomeScreen,
    page: HomeScreen,
    isPublic: true,
    exact: true,
  },



  {
    path: NameRoutes.AccessScreen,
    page: AccessScreen,
    isPublic: true,
    exact: true,
  },



  {
    path: NameRoutes.RankingsScreen,
    page: RankingsScreen,
    isPublic: true,
    exact: true,
  },



  {
    path: NameRoutes.PlansAllScreen,
    page: PlansAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.PlansFormScreen,
    page: PlansFormScreen,
    isPublic: true,
    exact: true,
  },



  // Secretio

  {
    path: NameRoutes.ModelesAproveAllScreen,
    page: ModelesAproveAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.ModelesDetailsScreen,
    page: ModelesDetailsScreen,
    isPublic: true,
    exact: true,
  },


  {
    path: NameRoutes.AntiFakeAllScreen,
    page: AntiFakeAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.PicturesAproveAllScreen,
    page: PicturesAproveAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.VideosAproveAllScreen,
    page: VideosAproveAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.ModelsScreen,
    page: ModelesDetailsScreen,
    isPublic: true,
    exact: true,
  },



  // Streams

  {
    path: NameRoutes.CategoriesShowAllScreen,
    page: CategoriesShowAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.CategoriesCreateScreen,
    page: CategoriesCreateScreen,
    isPublic: true,
    exact: true,
  },


  {
    path: NameRoutes.SubCategoriesShowAllScreen,
    page: SubCategoriesShowAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.SubCategoriesCreateScreen,
    page: SubCategoriesCreateScreen,
    isPublic: true,
    exact: true,
  },



  {
    path: NameRoutes.ChannelsShowAllScreen,
    page: ChannelsShowAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.ChannelsCreateScreen,
    page: ChannelsCreateScreen,
    isPublic: true,
    exact: true,
  },


  {
    path: NameRoutes.StarsShowAllScreen,
    page: StarsShowAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.StarsCreateScreen,
    page: StarsCreateScreen,
    isPublic: true,
    exact: true,
  },



  {
    path: NameRoutes.VideosShowAllScreen,
    page: VideosShowAllScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.VideosCreateScreen,
    page: VideosCreateScreen,
    isPublic: true,
    exact: true,
  },

];



export default ScreenRoutes;
