import React from 'react';

// import { Container } from './styles';



const VideosShowAllScreen: React.FC = () => {
  return (

    <div>

      <h2>Ver Videos</h2>

    </div>

  );
};



export default VideosShowAllScreen;
