import React,
{
  useEffect,
} from 'react';
import './App.css';

import {
  Provider,
} from 'react-redux';

import {
  persistStore,
} from 'redux-persist';

import {
  PersistGate,
} from 'redux-persist/integration/react';

import * as PackageInfo from '../package.json';

import Routes from './navigation/routes/app.routes';

import {
  storePersisted,
} from './redux';

// import SplashScreen from './screens/Splash';

const persistor = persistStore(storePersisted);



const App: React.FC = () => {
  const projectPackage = PackageInfo;

  useEffect(() => {
    console.log(`Conta Global Version: ${projectPackage.version}`);
  }, []);



  return (

    <Provider store={storePersisted}>
      <PersistGate loading={null} persistor={persistor}>

        {/* <SplashScreen> */}
        <Routes />
        {/* </SplashScreen> */}

      </PersistGate>
    </Provider>

  );
};



export default App;
