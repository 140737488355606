import React from 'react';

// import { Container } from './styles';

const ModelesDetailsScreen: React.FC = () => {
  return (

    <div>
      <h2>
        ModelesDetailsScreen
      </h2>

    </div>

  );
};



export default ModelesDetailsScreen;
