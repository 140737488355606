import React from 'react';
import HeaderContact from '../../components/Headers/HeaderContact';

// import { Container } from './styles';

const HomeScreen: React.FC = () => {
  return (

    <div>

      <HeaderContact />

    </div>

  );
};



export default HomeScreen;
