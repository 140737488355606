export const APP_ROUTES = {
  HomeScreen:                       '/',

  AccessScreen:                     '/access',
  
  RankingsScreen:                   '/ranking',

  PlansAllScreen:                   '/plans',
  PlansFormScreen:                  '/plans/form',


  // Secretio

  ModelesAproveAllScreen:           '/secretio/models',
  ModelesDetailsScreen:             '/secretio/models/:aliasname',
  AntiFakeAllScreen:                '/secretio/anti-fake',
  PicturesAproveAllScreen:          '/secretio/pictures',
  VideosAproveAllScreen:            '/secretio/videos',

  
  // Stream

  ModelsScreen:                     '/stream/models',
  
  CategoriesShowAllScreen:          '/stream/categories',
  CategoriesCreateScreen:           '/stream/categories/create',

  SubCategoriesShowAllScreen:       '/stream/subcategories',
  SubCategoriesCreateScreen:        '/stream/subcategories/create',

  ChannelsShowAllScreen:            '/stream/channels',
  ChannelsCreateScreen:             '/stream/channels/create',

  StarsShowAllScreen:               '/stream/stars',
  StarsCreateScreen:                '/stream/stars/create',
  
  VideosShowAllScreen:              '/stream/videos',
  VideosCreateScreen:               '/stream/videos/create',
};



const NameRoutes = {
  ...APP_ROUTES,
};



export default NameRoutes;
