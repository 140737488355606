import React from 'react';

// import { Container } from './styles';



const VideosCreateScreen: React.FC = () => {
  return (

    <div>
      <h2>
        Criar Videos
      </h2>

    </div>

  );
};



export default VideosCreateScreen;
