import React from 'react';

import {
  Formik,
  Field,
} from 'formik';

import * as Yup from 'yup';



const CategoriesCreateScreen = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
  });

  const initialValues = {
    name: '',
  };

  const saveCategory = (values: any) => {
    console.log(values);
  };



  return (

    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        saveCategory(values);
      }}>
      {({
        errors,
        touched,
        handleSubmit,
      }) => (

        <div>

          <h2>Criar Categorias</h2>


          <div>
            <label htmlFor="name">Nome da Categoria</label>
            <Field id="name" name="name" type="text" />
            {errors.name && touched.name ? (
              <div>{errors.name}</div>
            ) : null}
          </div>


          <button
            type="button"
            onClick={() => {
              handleSubmit();
            }}>
            Cadastrar
          </button>

        </div>

      )}
    </Formik>

  );
};



export default CategoriesCreateScreen;
