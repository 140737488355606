import React from 'react';

// import { Container } from './styles';



const ChannelsShowAllScreen: React.FC = () => {
  return (

    <div>

      <h2>Ver Canais</h2>

    </div>

  );
};



export default ChannelsShowAllScreen;
