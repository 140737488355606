import React from 'react';

// import { Container } from './styles';



const PicturesAproveAllScreen: React.FC = () => {
  return (

    <div>

      <h2>PicturesAproveAllScreen</h2>

    </div>

  );
};



export default PicturesAproveAllScreen;
