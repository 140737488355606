import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  modal: 'modalClose',
  drawer: false,
  snackBar: {},
};



// Action Types

export const USER_MODAL_ACTION = '@user/MODAL_ACTION';
export const APP_SNACKBAR_ACTION = '@user/SNACKBAR_ACTION';
export const APP_SNACKBAR_RESET = '@company/SNACKBAR_RESERT';
export const APP_DRAWER_ACTION = '@user/DRAWER_ACTION';



// Action Creators

export const modalAction = createAction<object>(USER_MODAL_ACTION);
export const snackbarAction = createAction<object>(APP_SNACKBAR_ACTION);
export const snackbarResetAction = createAction<object>(APP_SNACKBAR_RESET);
export const drawerAction = createAction<object>(APP_DRAWER_ACTION);



// Handlers

const modalReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.modal) {
    case 'modalClose': {
      return {
        ...state,
        modal: 'modalClose',
      };
    }
    case 'modalOpen': {
      return {
        ...state,
        modal: 'modalOpen',
      };
    }
    default:
      return state;
  }
};


const snackbarReducer = (state = INITIAL_STATE, action: any) => ({
  ...INITIAL_STATE,
});


const snackbarResetReducer = (state = INITIAL_STATE) => ({
  ...state,
  snackBar: INITIAL_STATE.snackBar,
});


const drawerReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  drawer: action.payload,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [modalAction.type]: modalReducer,
  [snackbarAction.type]: snackbarReducer,
  [snackbarResetAction.type]: snackbarResetReducer,
  [drawerAction.type]: drawerReducer,
});
