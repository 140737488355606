import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  TryRevalidateSession,
} from '../../axios/api/auth.api';

import {
  ETokenAsync,
} from '../../common/enums/token-async';

import {
  RootState,
} from '../../redux';

import {
  USER_FETCH_INFO,
  USER_RESET_USER,
} from '../../redux/reducers/user.store';

import {
  getLocalStorageItem,
} from '../../utils/local-storage.utils';

import NameRoutes from '../names';

import ScreenRoutes from './screens.routes';



function Routes() {
  const dispatch = useDispatch();


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);


  const ScreensApp = [
    ...ScreenRoutes,
  ];


  // Try to validate user and logged in
  async function loggedFunction() {
    if (!loggedIn) {
      const token = getLocalStorageItem(ETokenAsync.TOKEN);
      const userData = await TryRevalidateSession(token);

      if (userData) {
        dispatch({ type: USER_FETCH_INFO, payload: userData });
      }
      else {
        dispatch({ type: USER_RESET_USER, payload: {} });
      }
    }
  }



  useEffect(() => {
    loggedFunction();
  }, []);



  return (

    <BrowserRouter>
      <Switch>
        {ScreensApp.map(
          ({ path, page: Page, exact, isPublic }, index) => {
            return isPublic ? (

              <Route
                key={index}
                path={path}
                exact={exact}>

                <Page />

              </Route>

            ) : (

              <Route
                key={index}
                path={path}
                exact={exact}
                render={({ location /* , history */ }) => {
                  if (loggedIn) {
                    return <Page />;
                  }

                  // redirect to login page
                  return (

                    <Redirect
                      to={{
                        pathname: NameRoutes.AccessScreen,
                        // search: `?redirect=${location.pathname}`,
                        state: { from: location },
                      }}
                    />

                  );
                }}
              />

            );
          },
        )}
      </Switch>
    </BrowserRouter>

  );
}



export default Routes;
