import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import User from '../../axios/models/User';



const INITIAL_STATE = {
  actualUser: User,
  loading: false,
  loggedIn: false,
};



// Action Types

export const USER_FETCH_INFO = '@user/FETCH_INFO';
export const USER_RESET_USER = '@user/RESET';
export const USER_UPDATE = '@user/UPDATE';



// Action Creators

export const userAction = createAction<object>(USER_FETCH_INFO);
export const userResetAction = createAction<object>(USER_RESET_USER);
export const userUpdateAction = createAction<object>(USER_UPDATE);



// Handlers

const setUser = (state = INITIAL_STATE, action: any) => ({
  ...state,
  actualUser: action.payload,
  loggedIn: true,
});


const updateUser = (state = INITIAL_STATE, action: any) => ({
  ...state,
  actualUser: { ...state.actualUser, ...action.payload },
});


const resetUser = () => ({
  ...INITIAL_STATE,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [userAction.type]: setUser,
  [userUpdateAction.type]: updateUser,
  [userResetAction.type]: resetUser,
});
