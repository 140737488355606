import React from 'react';
import { useHistory } from 'react-router-dom';
import NameRoutes from '../../navigation/names';

// import { Container } from './styles';
import './styled.css';



const AccessScreen: React.FC = () => {
  const history = useHistory();

  return (

    <div className='login-container'>

      <div className="container">
        <h2 className="login-title">Log in</h2>

        <form className="login-form">
          <div>
            <label htmlFor="name">Name </label>
            <input
              id="name"
              type="text"
              placeholder="Eren Buruk"
              name="name"
              required
            />
          </div>

          <div>
            <label htmlFor="email">Email </label>
            <input
              id="email"
              type="email"
              placeholder="me@example.com"
              name="email"
              required
            />
          </div>

          <div>
            <label htmlFor="password">Password </label>
            <input
              id="password"
              type="password"
              placeholder="password"
              name="password"
              required
            />
          </div>

          <button className="btn-btn--form" type="submit" value="Log in"
            onClick={() => {
              history.replace(NameRoutes.HomeScreen);
            }}>
            Entrar
          </button>
        </form>
      </div>

    </div>

  );
};



export default AccessScreen;
