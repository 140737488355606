import axios from 'axios';

const baseURL = 'http://localhost:3333';



const ApiWS = axios.create({
  baseURL,
});



export default ApiWS;
