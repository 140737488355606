export const APPCOLORS = {
  primary:                          '#009688',
  primaryDark:                      '#09403C',
  secondary:                        '#2BDA8E',
  tertiary:                         '#FFE358',
  accent:                           '#00FFFF',
  danger:                           '#F51E1D',
  attention:                        '#FF7D35',
  wait:                             '#d829ff',
  accept:                           '#20DC62',
  disabled:                         '#757575',
};


export const APPDEFAULT = {
  black:                            '#000000',
  white:                            '#FFFFFF',
  lightGray:                        '#dbdbdb',
  gray:                             '#898989',
  muted:                            '#9FA5AA',
  transparent:                      'transparent',
  neutral:                          'rgba(255,255,255, 0.65)',
};


export const APPITEMS = {
  appBackground:                    '#15202b',
};



export const ALERTS = {
  alertOverlay:                     'rgba(0, 0, 0, 0.65)',
  alertBackground:                  'white',
  alertTitleColor:                  'black',
};


export const BUTTONS = {
  buttonOkDark:                     '#1B7272',
  buttonOk:                         '#11a7a1',
  buttonOkAccent:                   '#17d6cc',
  buttonOkLight:                    '#91DCD5',
  buttonCancelDark:                 '#501111',
  buttonCancel:                     '#822020',
  buttonCancelAccent:               '#ad2a2a',
  buttonSolid:                      '#002238',
  buttonDisabled:                   '#84878E',
};


export const CALENDAR = {
  calendarColorLight:               'rgba(23, 214, 204, 0.14)',
  calendarColor:                    'rgba(23, 214, 204, 0.35)',
};


export const CARD = {
  cardBackground:                   '#192734',
  cardOverlay:                      'rgba(0, 0, 0, 0.7)',
};


export const CARNIVAL = {
  // carnivalPurple:                   '#5E4395',
  carnivalPurple:                   '#9000C0',
  carnivalPink:                     '#D900D2',
  carnivalBlue:                     '#029ACC',
  carnivalGreen:                    '#02D388',
  carnivalYellow:                   '#F2C900',
  carnivalRed:                      '#FF0246',
};


export const LEVELS = {
  basicColor:                       '#00A5A5',
  premiumColor:                     '#ba0808',
  persasColor:                      '#a007f2',
  starColor:                        '#42A3FC',
  kingColor:                        '#DBA800',
};


export const MENU = {
  menuOverlay:                      'rgba(0, 0, 0, 0.8)',
  menuBackground:                   '#0a0c1a',
  menuLine:                         '#162F40',
  menuSeparator:                    '#404040',
};


export const PARENTAL = {
  plus14:                           '#FF8C00',
  plus16:                           '#FF0000',
  plus18:                           '#343434',
};


export const REFRESH = {
  refreshBackground:                '#303346',
};


export const TEXT = {
  textTitle:                        '#DEDEDE',
  textDescription:                  '#878787',
  textSubtitle:                     '#9DA2B2',
  textSubDescription:               '#696E7D',
  textApp:                          '#c7c7c7',
  subtextApp:                       'rgba(255, 255, 255, 0.5)',
};


export const TOOLBAR = {
  // Toolbar App
  toolbarBackground:                'rgba(21, 32, 43, 0.75)',
  toolbarLine:                      '#38444D',
  
  // Profile Colors
  profileToolbar:                   '#0A0A23',
  profileColorTop:                  '#131a1f',
  profileColorBottom:               '#404d55',

  // Navigation Bottom Colors
  navigationBottomBackground:       '#0A0A14',
  navigationBottomLine:             '#101827',
};


// Muted:                            '#9FA5AA',



const Colors = {
  ...APPCOLORS,
  ...APPDEFAULT,
  ...APPITEMS,
  ...ALERTS,
  ...BUTTONS,
  ...CALENDAR,
  ...CARD,
  ...CARNIVAL,
  ...LEVELS,
  ...MENU,
  ...PARENTAL,
  ...REFRESH,
  ...TEXT,
  ...TOOLBAR,
};



export default Colors;
