import React from 'react';

import { useHistory } from 'react-router-dom';

import CardModelApprove from '../../../../components/Cards/CardModelApprove';
import NameRoutes from '../../../../navigation/names';

// import { Container } from './styles';



const ModelesAproveAllScreen: React.FC = () => {
  const history = useHistory();


  return (

    <div>

      <h2>ModelesAproveAllScreen</h2>

      {/* TODO: Ao aprovar perguntar se mostra o rosto ou não */}

      <CardModelApprove
        onPress={() => {
          history.push(NameRoutes.ModelesDetailsScreen);
        }}
        onAntiFakePress={() => {
          history.push(NameRoutes.AntiFakeAllScreen);
        }}
        onPicturePress={() => {
          history.push(NameRoutes.PicturesAproveAllScreen);
        }}
        onVideoPress={() => {
          history.push(NameRoutes.VideosAproveAllScreen);
        }}
      />

    </div>

  );
};



export default ModelesAproveAllScreen;
