import React from 'react';

// import { Container } from './styles';

const PlansFormScreen: React.FC = () => {
  return (

    <div>

      <h2>
        Criar Planos
      </h2>

      <h2>
        Genero
      </h2>

      <h2>
        Nome do plano
      </h2>

      <h2>
        Valor
      </h2>

      <h2>
        Tempo de renovação
      </h2>

      <h2>
        Validade
        [Sem validade]
        [Adicionar validade]
      </h2>

    </div>

  );
};



export default PlansFormScreen;
